// Global Variables
@import "common/variables";

/*!
 * Custom Bootstrap Build
 * Bootstrap 4.0.0-alpha.6
 */

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/_variables.scss";
@import "../../bower_components/bootstrap/scss/_mixins.scss";
@import "../../bower_components/bootstrap/scss/_normalize.scss";
@import "../../bower_components/bootstrap/scss/_print.scss";
@import "../../bower_components/bootstrap/scss/_reboot.scss";
@import "../../bower_components/bootstrap/scss/_transitions.scss";
@import "../../bower_components/bootstrap/scss/_type.scss";
@import "../../bower_components/bootstrap/scss/_images.scss";
@import "../../bower_components/bootstrap/scss/_grid.scss";
@import "../../bower_components/bootstrap/scss/_tables.scss";
@import "../../bower_components/bootstrap/scss/_forms.scss";
@import "../../bower_components/bootstrap/scss/_buttons.scss";
@import "../../bower_components/bootstrap/scss/_button-group.scss";
@import "../../bower_components/bootstrap/scss/_input-group.scss";
@import "../../bower_components/bootstrap/scss/_custom-forms.scss";
@import "../../bower_components/bootstrap/scss/_nav.scss";
@import "../../bower_components/bootstrap/scss/_navbar.scss";
@import "../../bower_components/bootstrap/scss/_card.scss";
@import "../../bower_components/bootstrap/scss/_badge.scss";
@import "../../bower_components/bootstrap/scss/_alert.scss";
@import "../../bower_components/bootstrap/scss/_media.scss";
@import "../../bower_components/bootstrap/scss/_responsive-embed.scss";
@import "../../bower_components/bootstrap/scss/_close.scss";
@import "../../bower_components/bootstrap/scss/_carousel.scss";
@import "../../bower_components/bootstrap/scss/_utilities.scss";
// endbower

// Utilities
@import "utilities/grid";
@import "utilities/aspect-ratio";
@import "utilities/unit-calc";
@import "utilities/color-adjust";
@import "utilities/transitions";
@import "utilities/typography";

/*!
 * Avenue Rugs
 * Westguard Solutions
 */

// Global
@import "common/global";
@import "common/animation";

// Components
@import "components/navigation";
@import "components/buttons";
@import "components/comments";
@import "components/carousel";
@import "components/fancybox";
@import "components/forms";
@import "components/grid";
@import "components/plugins";
@import "components/widgets";
@import "components/wp-classes";

// Layouts
@import "layouts/content";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/archives";
@import "layouts/pages";
@import "layouts/fullpage";
@import "layouts/posts";
@import "layouts/tinymce";
