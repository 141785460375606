.fp-section {
	width: 100%;
	min-height: 10px;
	overflow: hidden;
}

.fullpage {
	color: $fullpage-tc;;
	position: relative;
	min-height: calc(100vh - 108px);

	.color-overlay {
		background-color: transparent;
		@include gradient-y(rgba(#545454,0.15), rgba(#000000,.4), 0%, 100%);
	}

	p {
		font-size: em-calc(30);
		@include font-smoothing;
		max-width: em-calc(648, 30);
		margin-bottom: 0;
		line-height: 1.2;
		margin-top: em-calc(20, 30);
	}

	h2 {
		color: #FFF;
		font-size: $font-size-fullpage-title;
		line-height: 0.9;
		text-align: left;
		@include font-smoothing;

		@include media-breakpoint-down(sm) {
			font-size: 2.4em;
		}

		i {
			font-family: $font-family-crimson-text;
			font-size: 1.083em;
		}

		small {
			font-family: $font-family-crimson-text;
			font-size: 0.25em;
			display: inline-block;
			vertical-align: middle;
			position: relative;
			padding: 0 em-calc(8);

			&:before,
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				border-top: 1px solid $fullpage-tc;
				left: 0;
			}

			&:before {
				top: em-calc(5) * -1;
			}

			&:after {
				bottom: em-calc(5) * -1;
			}
		}
	}
}

.slideup {
	.content {
		padding-bottom: 20vh;
	}

	.container-inner {
		margin-left: calc( #{$carousel-control-width / 4} - #{$fullpage-pagination-width - ($grid-gutter-width-base / 2 )} );
		margin-right: calc( #{$carousel-control-width / 4} - #{$fullpage-pagination-width - ($grid-gutter-width-base / 2 )} );
		padding-left: $fullpage-pagination-width;
		padding-right: $fullpage-pagination-width;
	}
}

.splash {
	.bx-wrapper,
	.bx-viewport {
		height: 100% !important;
	}

	h2 {
		margin-bottom: 0;
	}

	.teaser {
		position: absolute;
		bottom: 0;
		z-index: 60;
		height: 25vh;

		p {
			@include open-sans;
			font-size: em-calc(27);
			max-width: em-calc(470, 24);
			line-height: (32/24);
			color: $brand-vivid;
			text-transform: uppercase;
			margin-bottom: 0;
		}

		.follow-me {
			width: calc(24px + #{$spacer * 2});
			padding: $spacer;
			margin: $spacer 0;

			svg {
				fill: #D8D8D8;
				display: block;
				margin-bottom: 1px;
				animation: pulse 2s infinite;
				opacity: 0;

				&:first-child {
				  animation-delay: -0.75s;
				}

				&:nth-child(2) {
				  animation-delay: -0.5s;
				}

				&:nth-child(3) {
				  animation-delay: -0.25s;
				}
			}
		}
	}
}
