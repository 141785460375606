@function transitions($attributes, $duration, $easing){
	$transitions: ();
	@each $attribute in $attributes {
		$transitions: append($transitions, $attribute $duration $easing, comma);
	}
	@return $transitions;
}

@mixin transition($attributes:null, $duration: $transition-duration, $easing: $transition-easing) {
  @if $enable-transitions {
    @if ($attributes == null) {
      transition: $transition-base;
    } @else {
      transition: transitions($attributes, $duration, $easing);
    }
  }
}
