// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
  max-width: 100%;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
  max-width: 100%;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// Captions
.wp-caption {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}

.posts-navigation,
.pagination {
  margin-top: 4em;
  margin-bottom: 1em;
  display: inline-block;
  width: 100%;

  .nav-links {
    .nav-previous a,
    .nav-next a,
    .page-numbers,
    a.page-numbers {
      @extend .btn;
      @extend .btn-chic;
    }

    .nav-previous a,
    .nav-next a,
    a.page-numbers.prev,
    a.page-numbers.next {
      min-width: em-calc(200, 24);
      margin-top: $spacer;
      margin-bottom: $spacer;
    }

    .page-numbers.current {
      border: none;
      color: $gray-dark;
      padding-left: 0;
      padding-right: 0;
      &:hover {
        transform: none;
        cursor: default;
      }
    }
  }
}

body.paged .posts-navigation .nav-links::before {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: calc(50% - 3.3em);
  width: 6.6em;
  height: 5px;
  border-bottom: 1px solid $chic-border;
  box-shadow: 0 3px 0 0 $site-bg, -1px 2px 0px 0px $chic-border, 1px 2px 0px 0px $chic-border;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.posts-navigation .nav-links {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: relative;

  .nav-next {
    margin-left: auto;
  }

  @media (max-width: 650px) {
    flex-direction: column;

    .nav-next {
      margin-left: 0;
    }
  }
}

.pagination {
  text-align: center;

  .nav-links {
    @include media-breakpoint-down(sm) {
      display: table;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      .page-numbers {
        display: inline-block;
      }

      a.page-numbers.prev,
      a.page-numbers.next {
        display: block;
      }
    }

    .page-numbers,
    a.page-numbers {
      margin-top: $spacer;
      margin-right: $spacer;
      margin-bottom: $spacer;
      margin-left: $spacer;
    }

    a.page-numbers.prev,
    a.page-numbers.next {
      margin-top: $spacer;
      margin-right: 0;
      margin-bottom: $spacer;
      margin-left: 0;
    }
  }
}

.search-form {
  display: flex;
  flex-wrap: nowrap;

  .form-control {
    display: inline-block;
    margin-bottom: 0 !important;
    margin-right: .5rem;
    width: 100%;
    min-width: 11em;
    max-width: 20em;
  }
}
