//
// Constant Contact
//
.ctct-form > .ctct-button {
	display: none;
}

//
// Breadcrumbs NavXT
//
.breadcrumbs {
	color: $link-color;
	font-size: em-calc(14);
	text-transform: uppercase;
	@include josefin($font-weight-base, normal);

	> span {
		vertical-align: middle;
		padding: 2px 4px 0;
		display: inline-block;
		&:first-of-type {
			padding-left: 0;
		}
		&:last-of-type {
			visibility: hidden; // keep space for unifiormity
		}
	}
}


//
// Ninja Forms
//
.field-wrap {
	input[type="submit"],
	input[type="button"],
	button {
		&.btn-block {
			width: 100%;
		}
	}
}

.easyzoom--with-thumbnails {
	margin-bottom: 1rem;
}