//
// Font Families
//
// Josefin Sans
@mixin josefin($weight: 600, $spacing: 0.075em){
	font-family: $font-family-josefin;
	font-weight: $weight;
	text-transform: uppercase;
	@if (type-of($spacing) == number) {
		letter-spacing: $spacing;
	} @else {
		letter-spacing: normal;
	}
}

// Open Sans
@mixin open-sans($weight: 300){
	font-family: $font-family-open-sans;
	font-weight: $weight;
}

// Font Smoothing
@mixin font-smoothing($off: null) {
	@if ($off == 'off') {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	} @else {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

@mixin headings {
	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		@content;
	}
}
