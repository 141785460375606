.page-header {
	margin-bottom: $spacer;
	overflow: hidden;

	.breadcrumbs {
		margin-bottom: $spacer;
	}

	h1 {
		display: inline-block;
		position: relative;
		width: auto;
		padding-top: $josefin-line-height-fix;
		margin-top: -$josefin-line-height-fix;
		// &:before {
		// 	content: '';
		// 	display: block;
		// 	position: absolute;
		// 	top: 50%;
		// 	right: -70vw;
		// 	width: 67.7vw;
		// 	height: 8px;
		// 	background-image: radial-gradient(ellipse closest-corner at center center, #DEDEDE 0, #DEDEDE 2px, transparent 3px );
		// 	background-size: 26px 26px;
		// 	background-repeat: repeat-x;
		// 	background-position: left center;
		// }
	}
}

//
// Home Page
//
body.home {
	.section {
		position: relative;

		&.splash {
			background-color: #fff;
			height: 100vh;
			width: 100%;

			li {
				display: none;
			}
		}
	}

	#fp-nav {
		&.right {
			right: 15px;
		}

		ul li {
			margin: 7px 4px;

			&:nth-of-type(4) ~ li {
				display: none;
			}
		}
	}

	// Projects,
	// Patterns,
	// Testimonials
	.main-content {
		color: $text-gray;
		padding-top: 0;
		padding-bottom: 0;

		section {
			padding: (5em + $content-padding) 0;
		}

		h2,
		.h2 {
			margin-top: 0;
			padding-bottom: 0.3666em;
			margin-bottom: 1em;
			&:after {
				border-bottom-height: 4px;
			}

			a {
				border-bottom-height: 4px;
			}
		}

		h3,
		.h3 {
			@extend .archive-grid-title;
		}
	}
}

//
// Collator Page
//
body.page-template-template-collator .wrap, body.post-type-archive-patterns .wrap, body.post-type-archive-projects .wrap {
	@include media-breakpoint-down(md) {
		article {
			min-height: em-calc(156);
			&:before {
				display: none;
			}
		}
	}

	header {
		padding: 1em;
		width: 100%;
		height: 100%;
		background-color: $color-overlay-bg;
		position: absolute;
	}

	.entry-title {
		color: #FFF;
		font-weight: 600;
		margin: auto;
		padding-bottom: 0;
		text-align: center;
		white-space: pre-wrap;
		hyphens: auto;
		word-wrap: break-word;
		overflow-wrap: break-word;

		&:after {
			display: none;
		}
	}
}

//
// News Page
//
body.blog {
	.entry-title {
		margin-bottom: 0.15em;
	}
	.content-post > article:first-of-type .entry-title {
		margin-top: 0.2em;
	}
}

//
// Contact Page
//
body.contact {
	h5 {
		margin-bottom: 0;
	}
}

#swipebox-slider {
	overflow: visible !important;
}

.featured a:not(:first-child) {
	display: none;
}

.thumbnails li {
	margin-bottom: 1em;
}
