//
// Design Widget
//
.floating-cta {
  position: relative;
  display: inline-block;
  right: 0;
  float: right;
  //bottom: 0; // Set with data-offset-bottom-percent
  z-index: 1000;
  margin-left: auto;
  padding-left: 13px;

  &.fixed {
    position: fixed;
    //bottom: 20%; // Set with data-offset-bottom-percent
    max-width: 15em;

    @media (max-width: 320px), (max-height: 320px) {
      position: relative;
    }

    .design-widget::before {
      height: 40px;
      width: 40px;
      left: (40/3) * -1px;
      bottom: (40/3) * -1px;
    }
  }

  .design-widget {
    position: relative;
    display: block;
    @include josefin;
    font-size: $font-size-lg;
    line-height: 1.1;
    color: $gray;
    text-align: center;
    text-transform: uppercase;
    padding: 16px 30px 12px 40px;
    border-radius: 6px 0 0 6px;
    background-color: $logo-green;
    @include transition(background-color color);

    &::before {
      display: block;
      position: absolute;
      content: "";
      height: 33px;
      width: 33px;
      left: (33/3) * -1px;
      bottom: (33/3) * -1px;
      border-radius: 4px;
      background-color: $logo-brown;
      @include transition(background-color);
    }

    &:hover {
      color: darken($gray, 20%);
      background-color: darken($logo-green, 10%);
      &::before {
        background-color: darken($logo-brown, 10%);
      }
    }
  }
}

//
// Testimonials Carousel
//
.testimonials {
  .blockquote-reverse .blockquote-footer:after {
    content: '';
  }
  blockquote a {
    @extend .link;
  }
}

.testimonial-image img {
  top: 0;
  transform: translate(-50%, 0);
}
