// Top/bottom gutter generation
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		@each $direction, $abbreviation in (top: t, bottom: b) {
			$gutter: map-get($grid-gutter-widths, $breakpoint);

			@each $multiplier in (1, 2){

				@if ($multiplier > 1) {
					.g#{$abbreviation}#{$infix}-#{$multiplier} { margin-#{$direction}: $gutter !important; }
				} @else {
					.g#{$abbreviation}#{$infix} { margin-#{$direction}: $gutter / 2 !important; }
				}

			}
		}
	}
}
