// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$grid-gutter-width-base: 30px;

$content-padding: 2.375em;

// Columns
$site-width:            1440px;
$container-max:         1200px;
$oversize-load:         $site-width + $grid-gutter-width-base; // 1470

// Grid Breakpoints:
// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: $container-max
);

// Colors
$gray-dark:     #2E3532;
$gray:          #5D6361;
$gray-light:    #A7A7A7;
$gray-lighter:  #BEC1C0;
$gray-lightest: #F7F7F7;

$brand-primary: #D4DB90;
$brand-vivid:   #CFD68C;
$logo-brown:    #C8B18B;
$logo-green:    $brand-primary;

$site-bg:       #FFFFFF;
$footer-bg:     #1C1D1F; //#26272A;
$footer-tc:     #898989;
$copyright-bg:  #131416;
$copyright-tc:  #8D8D8D;

$shade-border:  #D2D2D2;
$shade-bg:      #F9F9F9;
$fullpage-tc:   #F0F3F2;

$chic-border:   #CBCBCB;
$chic-tc:       #777777;

$text-gray:     #888888;

$navbar-light-color:        $gray;
$navbar-light-active-color: $navbar-light-color;
$navbar-light-hover-color:  $navbar-light-color;

$color-overlay-bg: rgba(black, .3);

// This variable affects the `.h-*`, .w-*`, and `.aspect-*` classes.
$sizes: (
  25: 25%,
  33: (100%/3),
  50: 50%,
  66: (200%/3),
  75: 75%,
  100: 100%
);

// Fonts
$font-family-open-sans:    'Open Sans', sans-serif;
$font-family-josefin:      'Josefin Sans', sans-serif;
$font-family-crimson-text: 'Crimson Text', serif;
$font-family-base:         $font-family-open-sans;
$font-weight-base:         300;

$headings-font-family:     $font-family-open-sans;
$headings-font-weight:     300;
$headings-line-height:     1.4; //1.2 compensate for a:hover underline

$josefin-line-height-fix:  .23em;

$font-size-fullpage-title: 4.5rem;
$font-size-h1:             4rem;
$font-size-h2:             3.5rem;
$font-size-h3:             2.5rem;
$font-size-h4:             2rem;
$font-size-h5:             1.666rem;
$font-size-h6:             1.333rem;

// Links
$link-color:            $gray-light;
$link-hover-color:      $brand-vivid;
$link-hover-decoration: none;

// Buttons
$btn-font-family:        $font-family-josefin;
$btn-font-weight:        600;
$btn-box-shadow:         none;
$btn-border-radius:      0;
$input-btn-border-width: 0;
$btn-padding-y:          0.75rem;
$btn-box-shadow-axis:    0 9.5px 0 -6px;

$btn-secondary-bg:       #e8e8e8;
$btn-secondary-color:    $gray;

// Transition Duration
$transition-duration: 0.2s;
$transition-easing:   ease-in-out;

// BS Carousel (style only)
$carousel-control-icon-width: 40px;
$carousel-control-icon-height: 40px;

// BX-Slider
$carousel-control-width:             10%;
$carousel-indicator-color-dark:      $gray;
$carousel-control-prev-icon-bg-dark: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B8BBBA' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
$carousel-control-next-icon-bg-dark: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B8BBBA' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");

// fullPage.js
$fullpage-pagination-width: 52px;

// Tables
$table-border-color: $shade-border;
$table-head-color:   $gray-dark;
$table-head-bg:      $shade-bg;

// Forms
$input-padding-y:                .75rem !default;

$input-border-color: #e8e8e8;
$input-border-radius: 0;
$input-border-focus: #BFBFBF;
