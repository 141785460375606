.p-static   { position: static !important; } // default
.p-relative { position: relative !important; }
.p-absolute { position: absolute !important; }
.p-fixed    { position: fixed !important; }

.body-bg { background-color: $body-bg; }

body:not(#tinymce) {
	// Force footer to bottom
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	> header,
	> footer { flex: 0 0 auto; }
	.wrap { flex: 1 0 auto;	}

	// Prevent scaling below 320px
	min-width: 320px;
	> * {
		overflow-x: hidden;
	}
}

.wrap {
	position: relative;
	overflow-x: visible !important;
}

.bg-img-cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		width: auto;
		max-height: none;
		max-width: none;
		min-height: 100%;
		min-width: 100%;
		transform: translate(-50%,-50%);
		height: auto;
	}

	&.parallax {
		clip: rect(auto,auto,auto,auto);

		img {
			position: fixed !important;
		}
	}
}

.main-content {
	padding-top: $spacer-x;
	padding-bottom: $content-padding;
}

.oversize-container {
	padding-left: $spacer;
	padding-right: $spacer;
}

.oversize-load {
	max-width: $oversize-load;
	margin-right: auto;
	margin-left: auto;
}

.container-inner {
	margin-left: 10%;
	margin-right: 10%;
}

.container-max {
	@extend .container-fluid;
	max-width: $container-max;
}

.give-contrast {
	border-bottom: $border-width solid $shade-border;
	border-top: $border-width solid $shade-border;
	background-color: $shade-bg;
}

.throw-shade {
	box-shadow: 0 5px 4px 4px rgba(#000, 0.8);
	position: relative;
	z-index: 1;
}

.color-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $color-overlay-bg;
}

.archive-grid-title {
	color: $gray-dark;
	font-family: $font-family-open-sans;
	font-size: 1.6rem;
	text-transform: none;
	margin-top: 1rem;
	margin-bottom: 0.2rem;
}

.item-meta {
	@extend .list-inline;
	font-size: 1.2em;
	margin-bottom: 0;
}
