footer.content-info {
	a {
		color: $footer-tc;

		@include hover-focus {
			color: shade($brand-vivid, 30%);
		}
	}

	h1 {
		display: inline-block;
		@include josefin;
		font-size: em-calc(20);
		padding-bottom: em-calc(5);
		margin-bottom: em-calc(20);
		position: relative;

		&:before {
			background-color: $footer-tc;
			bottom: 0;
			content: "";
			display: block;
			height: $border-width;
			margin-bottom: -2px;
			position: absolute;
			width: 50%;
		}
	}

	.widgets,
	.copyright {
		@extend .oversize-container;
	}

	.main {
		color: $footer-tc;
		padding-top: $content-padding;
		padding-bottom: $content-padding;
		background-color: $footer-bg;
	}

	.brand {
		padding-bottom: $content-padding;
		p {
			margin-bottom: em-calc(10);

			&.indent {
				padding-left: 1.35rem;
			}

			&:last-of-type {
				margin-bottom: em-calc(20);
			}
		}

		p[itemprop="logo"] {
			margin-bottom: em-calc(28);
		}

		svg {
			max-width: 16.75em;

			.sq-l,
			.sq-r { fill: #353535; }
			.avenue,
			.rugs { fill: #898989; }
			.arr { fill: #626262; }
		}
	}

	.footer-navigation > ul {
		display: inline-block;
		@include josefin(300, normal);
		font-size: em-calc(18);
		line-height: 1.4;
		margin: 0 auto;
		position: relative;
		z-index: 10;

		@include media-breakpoint-up(md) {
			margin-left: 0;
		}

		li + li, .sub-menu {
			margin-top: em-calc(10, 18);
		}

		.sub-menu {
			font-size: em-calc(18, 18);
			list-style: none;
			padding-left: em-calc(28, 18);
		}
	}

	.sidebar-footer {
		padding-top: $content-padding;
		padding-bottom: $content-padding;

		.widget {
			max-width: em-calc(280);
			@include open-sans;
			margin: 0 auto;

			@include media-breakpoint-up(md) {
				margin-right: 0;
			}

			p {
				font-size: em-calc(21);
				line-height: 1.2;
				@include font-smoothing;
				margin-bottom: em-calc(20);
			}

			.btn {
				width: 100%;
				color: $footer-bg;
				background-color: $footer-tc;
				border-color: $footer-tc;
				font-size: em-calc(15);
				text-shadow: 1px 1px 0px #909090;
				@include hover-focus {
					background-color: lighten($footer-tc, 5%);
				}
				&:after {
					background: $brand-vivid;
				}
			}

			small {
				display: block;
				margin-top: em-calc(12);
				font-size: em-calc(11);
				width: 100%;
				text-align: center;
				color: darken($footer-tc, 12%);
			}
		}
	}

	.scrolltop {
		@include media-breakpoint-up(md) {
			margin-top: em-calc(27) * -1;
		}

		svg {
			transform: rotate(180deg);
			max-width: 1em;
			fill: $footer-tc;
			@include transition(fill);
		}

		a:hover svg {
			fill: shade($brand-vivid, 30%);
		}
	}

	.locations ul,
	.copyright p {
		margin: 0;
	}

	.locations a {
		line-height: 1;
		padding-bottom: 1.5em;
		padding-top: 1.5em;
		@include transition(color background-color);
		@include hover-focus {
			background-color: $shade-border;
			color: $copyright-bg;
		}
	}

	.copyright {
		font-size: em-calc(14);
		color: $copyright-tc;
		padding-top: $content-padding;
		padding-bottom: $content-padding;
		background-color: $copyright-bg;
	}
}
