.main-content,
body#tinymce {
	@include headings {
		color: $gray;
		@include font-smoothing();
		display: inline-block;
		width: 100%;
		margin-bottom: $spacer-y;

		a {
			color: inherit;
			border-bottom: 3px solid transparent;
			padding-bottom: inherit;
			@include transition(border-bottom-color);

			@include hover-focus {
				color: inherit;
				border-bottom-color: $link-hover-color;
			}
		}

		sup {
			top: -1em;
			font-size: 50%;
		}

		&.screen-reader-text {
  		@extend .sr-only;
  		@extend .sr-only-focusable;
		}
	}

	h1, h2,
	.h1, .h2 {
		@include josefin($headings-font-weight, -0.01em);
	}

	h1 {
		@include media-breakpoint-down(sm) {
			font-size: 2.7em;
		}
	}

	h2,
	.h2 {
		line-height: 1;
		padding-bottom: .32em;
		position: relative;
		padding-top: $josefin-line-height-fix;
		margin-top: 1em - $josefin-line-height-fix;
		margin-bottom: 0.35em;
		padding-bottom: 0.1em;

		&:after {
			bottom: 0;
			content: "";
			display: block;
			border-bottom: 3px solid $brand-primary;
			margin-bottom: -3px;
			position: absolute;
			width: 68px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 2.4em;
		}
}

	h3, h4, h5, h6,
	.h3, .h4, .h5, .h6 {
		margin-top: $spacer-y;
	}

	// Swap margin from page-header to first sub-heading as to not affect other containers
	.page-header {
		  h1,   .h1 { margin-bottom: 0; }
		+ h2, + .h2 { margin-top: 1rem; }
	}
	// Remove margin from directly stacked subheadings
	h1, .h1 { + h2, + .h2 { margin-top: 0; } }
	h2, .h2 { + h3, + .h3 { margin-top: 0; } }
	h3, .h3 { + h4, + .h4 { margin-top: 0; } }
	h4, .h4 { + h5, + .h5 { margin-top: 0; } }
	h5, .h5 { + h6, + .h6 { margin-top: 0; } }

	thead {
		th, td {
			@include josefin(600, 0.03em);
			padding-top: 1rem;
		}
	}

	// Don't apply to homepage!
	&:not(.section) {
		p,
		ul,
		dl,
		ol,
		pre {
			font-size: 1.25rem;
			letter-spacing: 0.001em;

			a:not(.btn) {
				@extend .link;
			}
		}

		p  p,
		ul ul,
		ol ol,
		dl dl {
			font-size: 1.1rem;
			margin-top: $spacer * .333;
			margin-bottom: $spacer;
		}

		p + ul {
			margin-top: -0.5rem;
		}
	}

	.link {
		border-bottom: 1px solid transparent;
		border-bottom-color: inherit;
	}

	b, strong {
		font-weight: 600;
	}

	a {
	 	@include transition(color);
	}

	p a {
		@include font-smoothing(off);
	}

	a.btn {
		color: inherit;
		&:not(.btn-chic) {
			border-bottom: none !important;
		}
	}

	blockquote.blockquote {
		> p {
			color: $text-gray;
			font-family: $font-family-crimson-text;
			font-size: 1.4em;
			line-height: 1.2;
			text-align: left;
			@include font-smoothing;
			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.blockquote-footer {
			display: inline-block;
			width: 100%;
			margin-top: $spacer-y;

			h1 {
				font-family: $font-family-open-sans;
				font-size: 1.6em;
				font-weight: 200;
				margin-bottom: 0;
			}

			p {
				color: $link-color;
				font-family: $font-family-open-sans;
				font-size: 1.2em;
				font-style: normal;
				font-weight: 200;
				text-align: right;
			}
		}
	}
}

// Content Archive Grid
.content-patterns,
.content-projects {
	.entry-title {
		@extend .archive-grid-title;
		&:after {
			display: none;
		}
	}
}

.featured {
	margin-bottom: 1rem;
}