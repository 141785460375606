button:hover,
.button:hover,
.btn:hover,
input[type="button"]:hover {
	cursor: pointer;
}

.btn {
	@include josefin;
	@include font-smoothing(off);
	line-height: 1;
	position: relative;
	transition: $transition-duration;
	padding-top: $btn-padding-y + 0.25;
	margin-bottom: 3px;
	vertical-align: baseline;
  margin-top: 3px;
  margin-bottom: 3px;
  white-space: normal;
  p & {
    margin-top: -1em;
    margin-bottom: -1em;
  }
	&:after {
	  position: absolute;
	  transition: $transition-duration;
	  content: '';
	  left: 0;
	  bottom: -3px;
	  height: 3px;
	  width: 0;
	  background: $brand-vivid;
	}
	@include hover-focus {
		&:after {
			width: 100%;
	  }
	}

	&.btn-primary:after { background: darken($btn-primary-bg, 30%); }
	&.btn-secondary:after { background: darken($btn-secondary-bg, 30%); }
	&.btn-info:after { background: darken($btn-info-bg, 30%); }
	&.btn-success:after { background: darken($btn-success-bg, 30%); }
	&.btn-warning:after { background: darken($btn-warning-bg, 30%); }
	&.btn-danger:after { background: darken($btn-danger-bg, 30%); }
}

.btn-chic {
	color: $chic-tc;
	font-size: em-calc(24);
	font-weight: 300;
	padding: .66em .84em .44em;
	border-top: $border-width solid $chic-border;
	border-bottom: $border-width solid $chic-border;
	margin-bottom: 0;
	background-color: transparent;
	&:before,
	&:after {
		position: absolute;
    transition: $transition-duration;
    content: '';
    height: 2px;
    width: 0;
    background: $brand-vivid;
	}
  &:before { left: 0; top: -2px; }
  &:after { left: 100%; bottom: -2px; }

  &.btn-primary { &:before, &:after { background: $btn-primary-bg; } }
  &.btn-secondary { &:before, &:after { background: $btn-secondary-bg; } }
  &.btn-info { &:before, &:after { background: $btn-info-bg; } }
  &.btn-success { &:before, &:after { background: $btn-success-bg; } }
  &.btn-warning { &:before, &:after { background: $btn-warning-bg; } }
  &.btn-danger { &:before, &:after { background: $btn-danger-bg; } }

	@include hover-focus {
		background-color: transparent;
    &:before, &:after { width: 100%; left: 0;}
    &.btn-primary { border-color: $chic-border; }
    &.btn-secondary { border-color: $chic-border; }
    &.btn-info { border-color: $chic-border; }
    &.btn-success { border-color: $chic-border; }
    &.btn-warning { border-color: $chic-border; }
    &.btn-danger { border-color: $chic-border; }
	}
}
