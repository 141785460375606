header.banner {
	@include josefin;

	.navbar-toggler {
		margin-top: 0.58em;
		@include media-breakpoint-up(md) {
			margin-top: 1.1em;
		}
	}

	.navbar-brand {
		width: 100%;
 		max-width: 6em;
 		line-height: 0;
 		@include media-breakpoint-up(md) { max-width: 8.2em; }
		@include media-breakpoint-up(lg) { max-width: 9em; }

		svg {
			.sq-l { fill: $logo-brown; }
			.sq-r { fill: $logo-green; }
			.avenue { fill: #4D5C53; }
			.rugs { fill: #3C3D3D; }
			.arr { fill: #B0B0B0; }
		}
	}

	.navbar-nav {
		flex-grow: 1;
		max-width: 68em;
	}

	.nav-link {
		font-size: $font-size-lg;
		line-height: 1;
		text-transform: uppercase;
		position: relative;
		display: inline-block;

		&:after {
			display: block;
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 3px;
			background: $brand-vivid;
			opacity: 0;
			@include transition(opacity transform);
			transform: translateY(-0.35em);
		}
	}

	.nav-link:hover,
	.nav-link:focus,
	.nav-item.active .nav-link {
		&:after {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
