ul.carousel {
	margin-bottom: 0;
	height: 100%;
	> li {
		height: 100%;

		> .row {
			height: 100%;
		}
	}
}

.carousel-control-next,
.carousel-control-prev {
	opacity: 1;
	@include hover-focus {
		opacity: 1;
	}

	a {
		opacity: .5;
		@include hover-focus {
			opacity: .9;
		}
	}
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	background-size: 28px;
}

.carousel-control-prev-icon {
	background-image: $carousel-control-prev-icon-bg-dark;
}

.carousel-control-next-icon {
	background-image: $carousel-control-next-icon-bg-dark;
}

.container-carousel.oversize-load {
	position: relative;

	// Only first-level carousels
	.carousel-container > .bx-wrapper {
		position: static;

		> .bx-has-pager {
			bottom: auto;
			top: 0;
			right: 0;
			position: absolute;

			.bx-pager {
				position: static;
				bottom: auto;
				width: auto;
				padding: 0;
			}

			@include media-breakpoint-down(sm) {
				left: 0;
		  	top: 75px;
	  	}
		}

		.bx-prev,
		.bx-next {
			width: 100%;
			height: 100%;
			max-height: 375px;
		}

		.bx-prev .carousel-control-prev-icon,
		.bx-next .carousel-control-next-icon {
			width: 100%;
			height: 100%;
		}

		> .carousel-control-next,
		> .carousel-control-prev {
			position: absolute;
			width: $carousel-control-width;
			top: 0;
		}

		> .carousel-control-prev {
			left: 0;
		}

		> .carousel-control-next {
			right: 0;
		}
	}
}

// BX Slider
.bx-wrapper {
	box-shadow: none;
	border: none;
	background: transparent;
	margin: 0;
	position: relative;
	width: 100%;

	.bx-loading {
		background: url('../images/loading.gif') center center no-repeat;
	}

	.bx-pager.bx-default-pager a {
		background-color: $gray-lighter;
		&.active {
			background-color: $gray;
		}
	}
}

// Carousel-within-a-carousel
.carousel-child .bx-has-pager .bx-pager {
	position: absolute;
	right: 0;
	bottom: 10px;
	left: 0;
	display: flex;
	justify-content: center;
	margin-right: $carousel-control-width;
	margin-left: $carousel-control-width;
	padding-top: 0;
	padding-bottom: 0;
	top: auto;
	width: auto;

	.bx-pager-item {
		flex: 1 0 auto;
		max-width: $carousel-indicator-width;
		margin-right: $carousel-indicator-spacer;
		margin-left: $carousel-indicator-spacer;
	}

	a {
		position: relative;
		width: 100%;
		height: $carousel-indicator-height;
		background-color: rgba($carousel-indicator-active-bg, .5);
		border-radius: 0;

		// Use pseudo classes to increase the hit area by 10px on top and bottom.
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}

		@include hover-focus {
			background-color: $carousel-indicator-active-bg;
		}

		&.active {
			background-color: $carousel-indicator-active-bg;
		}
	}
}

.carousel-container blockquote {
	border: none;
	margin-bottom: 0;
}
